import { datadogRum } from "@datadog/browser-rum";
import LogRocket from "logrocket";
import { getSegmentDestinationsFromCookies } from "@/features/Analytics/helpers/cookieManagement";
import { isLogRocketEnabled } from "@/features/Analytics/helpers/logRocket";
import { EventDestinations } from "@/features/Analytics/sharedTypes";
import type {
  TrackFunctionType,
  IntegrationsFlags,
} from "@/features/Analytics/sharedTypes";
import { internalTrackError } from "@/helpers/errorTracking";
import { getPersistedStateValue } from "@/hooks/useLocalPersistedState";
import { LocalPersistedStateKeys } from "@/hooks/useLocalPersistedState/types";

/**
 * Track an event in third-party services
 *
 * The `eventProperties` parameter is required if the eventName has a schema
 * definition with required properties.
 *
 * If the schema defined for the eventName is a type with all of its properties
 * as optional, or is an empty object, `eventProperties` becomes optional.
 *
 * Accepts an optional `disableAmplitudeDestinationOnSegment` that blocks marked
 * events from going to Amplitude destination to avoid using up our quota.
 *
 * @example
 * const eventPropsWithOptionalKey = {
 *   userId: string;
 *   somethingElse?: number;
 *   disableAmplitudeDestinationOnSegment?: boolean
 * }
 */
export const trackEvent: TrackFunctionType = (
  eventName,
  eventProperties,
  callback,
  destinations = EventDestinations,
  enabledSegmentDestinations = undefined,
) => {
  if (destinations.includes("segment")) {
    const segmentDestinationsFromCookies = getSegmentDestinationsFromCookies();

    const segmentDestinationKeys = Object.keys(
      segmentDestinationsFromCookies,
    ) as Array<keyof typeof segmentDestinationsFromCookies>;

    const segmentDestinations = !enabledSegmentDestinations
      ? segmentDestinationsFromCookies
      : segmentDestinationKeys.reduce<IntegrationsFlags>((acc, destination) => {
          if (!enabledSegmentDestinations.includes(destination)) {
            acc[destination] = false;
          }

          return acc;
        }, segmentDestinationsFromCookies);

    try {
      window.analytics.track(
        eventName,
        {
          ...eventProperties,
        },
        {
          integrations: segmentDestinations,
        },
        callback,
      );
    } catch (err) {
      console.error("Failed to track event in Segment");
      internalTrackError({
        errorName: "Failed to track event in Segment",
        error: err as Error,
        context: {
          eventName,
          eventProperties,
        },
      });
    }
  }

  if (
    process.env.APP_ENV !== "prod" &&
    process.env.NODE_ENV !== "test" &&
    getPersistedStateValue(LocalPersistedStateKeys.DEV_ANALYTICS_LOGGING, false)
  ) {
    // log to console during development
    // eslint-disable-next-line no-console
    console.info(
      `%c Tracking %c ${eventName}`,
      "color: orange",
      "color: limegreen",
      eventProperties,
    );
  }

  if (destinations.includes("datadogRum")) {
    // Track events in DataDog
    try {
      datadogRum.addAction(eventName, eventProperties);
    } catch (err) {
      console.error("Failed to track event in DataDog");
      internalTrackError({
        errorName: "Failed to track event in DataDog",
        error: err as Error,
        context: {
          eventName,
          eventProperties,
        },
      });
    }
  }

  // track event in LogRocket on production
  // always check before tracking that the corresponding cookie category is enabled.
  if (isLogRocketEnabled && destinations.includes("logRocket")) {
    try {
      LogRocket.track(eventName, eventProperties);
    } catch (err) {
      console.error("Failed to track event in LogRocket");
      internalTrackError({
        errorName: "Failed to track event in LogRocket",
        error: err as Error,
        context: {
          eventName,
          eventProperties,
        },
      });
    }
  }
};

/**
 * Only use this if you expect your events are triggered a lot for anonymous
 * users and you don't care about those anonymous users' events. This helps us
 * limit the number of identified users and lowering our cost with Segment.
 * @returns a function to track events only for authenticated users. It will be
 * a no-op when this function is called for anonymous users.
 */
export const trackEventForAuthenticatedUsersOnly: TrackFunctionType = (
  eventName,
  eventProperties,
  callback,
) => {
  const authenticatedUserId =
    window.analytics &&
    typeof window.analytics?.user === "function" &&
    typeof window.analytics?.user()?.id === "function" &&
    window.analytics?.user?.()?.id?.();

  if (authenticatedUserId) {
    trackEvent(eventName, eventProperties, callback);
  }
};
